import AOS from 'aos';
import 'aos/dist/aos.css';


Array.from(document.querySelectorAll('.tab-header')).map( item => {
  item.addEventListener('click', (e) => {
    const tabContent = e.target.parentNode.querySelector('.tab-content');

    Array.from(document.querySelectorAll('.tab-content.is-active')).map( item => {
      item.classList.remove('is-active')
    })

    tabContent.classList.add('is-active')
  })
})

Array.from(document.querySelectorAll('.experience-accordion li')).map( item => {
  item.addEventListener('click', (e) => {
    console.log(e.target);
    e.target.classList.toggle('is-active');
    // e.target.querySelector('p').classList.toggle('is-active');
  });
});

document.querySelectorAll('a[href^="#"]').forEach(anchor => {
  anchor.addEventListener('click', function (e) {
    e.preventDefault();

    document.querySelector(this.getAttribute('href')).scrollIntoView({
      behavior: 'smooth'
    });
  });
});


AOS.init();

